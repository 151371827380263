
import { Pedido } from '@/core/models/compras';
import { PageBase } from '@/core/models/shared';
import { UsuarioService } from '@/core/services/cadastros';
import { PedidoService } from '@/core/services/compras/PedidoService';
import { Component } from 'vue-property-decorator';


@Component
export default class RelatorioPedidoCompra extends PageBase { 

    service = new PedidoService();
    item: Pedido = new Pedido();
    overlay: boolean = false;
    usuarios:[] = [];

    mounted() {
        const filter = this.$route.query;
        this.overlay = true;
        this.service.ListarItensComFiltro(filter, 'Pedido.Orcamento, Pedido.Fornecedor, Pedido.Empresa, Produto')
            .then(
                res=>{
                    this.item = res.data.items;
                },
                err=>{
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{
                this.overlay = false;
            });

        new UsuarioService().ListarTudo().then(
            res => {this.usuarios = res.data.items}
        )
    }
}
